import React, { useEffect, useState } from 'react';
import Vimeo from './vimeo';

function Asset(props) {
  const [paddingTop, setPaddingTop] = useState(0);
  const [src, setSrc] = useState('')

  if (props.asset) {
    useEffect(() => {
      const asset = props.asset
      setSrc(asset.link)
      setPaddingTop(asset.aspectRatio ? `${100 / asset.aspectRatio}%` : '100%')
    }, [])

    return (
      <Vimeo
        className={props.className}
        paddingTop={paddingTop}
        playMode={props.playMode}
        videoMobFull={props.videoMobFull}
        videoDeskFull={props.videoDeskFull}
        url={src}
        mobileClass={props.mobileClass}
      />
    );
  } else {
    return null;
  }
}

export default Asset;
