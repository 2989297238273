import React from "react";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

class LottieAnim extends React.Component {
  constructor(props) {
    super(props);
    this.player = React.createRef(); // initialize your ref
  }
  render() {
    return (
      <Player
        ref={this.player} // set the ref to your class instance
        autoplay={true}
        loop={true}
        controls={false}
        src={this.props.asset.url}
      ></Player>
    );
  }
}

export default LottieAnim;
