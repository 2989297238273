import React from 'react';
import ResponsiveAssetImage from './responsive-asset-image';
import AssetVimeo from './assetVimeo';
import AssetLottie from './assetLottie';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { isServerSide } from '../utils/generalFun';

function Asset(props) {
  const breakpoints = useBreakpoint();
  const asset = props.asset;

  if (!asset) return null;

  if (asset._type == 'assetImage') {
    return (
      <ResponsiveAssetImage {...props} />
    );
  }

  if (asset._type == 'assetVimeo') {
    const playMode = asset.playMode;
    const defaultAsset = asset.videoMobile ?? asset.videoDesktop;
    const responsiveAsset = (isServerSide() || breakpoints.sm) ? defaultAsset : asset.videoDesktop;
    return (
      <AssetVimeo
        {...props}
        asset={responsiveAsset}
        playMode={playMode}
        caption={asset.caption}
      />
    );
  }

  if (asset._type == 'assetLottie') {
    const defaultAsset = asset.lottieMobile ?? asset.lottieDesktop;
    const responsiveAsset = (isServerSide() || breakpoints.sm) ? defaultAsset : asset.lottieDesktop;
    return <AssetLottie {...props} asset={responsiveAsset.asset} caption={asset.caption} />;
  }
}

export default Asset;
