import { Link } from 'gatsby';
import React from 'react';
import Asset from './asset';
import styles from './projects-grid.module.scss';
import theme from '../styles/theme.module.scss';
import { getMobileOS, isServerSide } from '../utils/generalFun';
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const OS = getMobileOS();

function IntroGrid(props) {
  // const [inHover, setHover] = useState(false);
  const breakpoints = useBreakpoint();
  const assetWidth = (isServerSide() || breakpoints.sm) ? 75 : (breakpoints.md ? 90 : 150);

  return (
    <div className={`${styles.root} ${theme.gdcont} ${theme.gmcont} project-grid`}>
      {props.controls && (
        <>
          <div className={`${styles.header} ${theme.gdmain} ${theme.gmmain} ${theme.mT6}`}>
            <h2 className={`${theme.h5_founders}`}>Our Work</h2>
            <Link className={`${theme.h5_founders} ${theme.underlined}`} to={`/work/`}>
              View All
            </Link>
          </div>
        </>
      )}

      <ul
        className={`${styles.list} ${theme.gdmain} ${theme.gmmain} ${theme.fdcont} ${theme.fscont} ${theme.fmbet}`}>
        {props.nodes &&
          props.nodes.map((node, index) => (
            <li
              key={index}
              className={`${styles.griditem} ${theme.fd2} ${theme.fs1} ${theme.fm1} ${
                node.assetHover[0] ? styles.hover : ''
              }`}>
              <div className={`${node.assetHover[0] ? styles.hoverinner : ''}`}>
                <Link
                  to={`/work/${node.projectLink.slug.current}`}
                  className={`${styles.thumb}  ${OS === 'iOS' ? '' : styles.customHover}`}>
                  <Asset
                    asset={node.assets[0]}
                    width={assetWidth}
                    style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                    loading="eager"
                    fetchpriority="high"
                  />
                </Link>
                {node.assetHover[0] && !breakpoints.sm && (
                  <Link
                    to={`/work/${node.projectLink.slug.current}`}
                    className={`${styles.hoverThumb} ${OS === 'iOS' ? '' : styles.customHover2}`}>
                    <Asset
                      asset={node.assetHover[0]}
                      width={assetWidth}
                      style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                      loading="eager"
                      fetchpriority="high"
                    />
                  </Link>
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default IntroGrid;
