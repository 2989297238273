import React from 'react'
import styles from './responsive-asset-image.module.scss';
import AssetImage from './assetImage';

// This component tricky helps us to lazy load the image based on the screen size
// by combining existing AssetImage (that only support one asset)
// with "display: none" (for lazy-load behavior of browser)
const ResponsiveAssetImage = (props) => {
  const { asset } = props
  if (asset?._type !== 'assetImage') return null

  const mobileAsset = asset.imageMobile
  const desktopAsset = asset.imageDesktop

  if (mobileAsset && desktopAsset) {
    return (
      <>
        <AssetImage {...props} asset={mobileAsset.asset} alt={mobileAsset.alt} caption={asset.caption} className={styles.mobileAssetImage} />
        <AssetImage {...props} asset={desktopAsset.asset} alt={desktopAsset.alt} caption={asset.caption} className={styles.desktopAssetImage} />
      </>
    )
  }

  const fallbackAsset = mobileAsset ?? desktopAsset // Sometimes we only have desktop asset

  return (
    <AssetImage {...props} asset={fallbackAsset.asset} alt={fallbackAsset.alt} caption={asset.caption} />
  )
}

export default ResponsiveAssetImage;
