import React, { Component } from 'react';
import useState from 'react';
import ReactPlayer from 'react-player/lazy';
import theme from '../styles/theme.module.scss';

class Video extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    url: null,
    pip: false,
    playing: false,
    controls: false,
    volume: 1,
    muted: true,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: true,
  };

  stopMovie = () => {
    this.setState({ playing: false });
  };

  playMovie = () => {
    this.setState({ playing: true });
  };

  ref = player => {
    this.player = player;
  };

  render() {
    const { url, playing, controls, volume, muted, loop, played, loaded, pip } = this.state;
    const SEPARATOR = ' · ';
    const divStyle = {
      paddingTop: this.props.paddingTop,
    };

    if (this.props.playMode == 'hover') {
      return (
        <div
          className={`${theme.playerWrapper} ${this.props.className}`}
          style={divStyle}
          onMouseOver={this.playMovie}
          onMouseOut={this.stopMovie}>
          <ReactPlayer
            ref={this.ref}
            className={`${theme.reactPlayer}`}
            width='100%'
            height='100%'
            playing={playing}
            url={this.props.url}
            controls={controls}
            loop={loop}
            muted={true}
          />
        </div>
      );
    } else if (this.props.playMode == 'controls') {
      return (
        <div className={`${theme.playerWrapper} ${this.props.className}`} style={divStyle}>
          <ReactPlayer
            className={`${theme.reactPlayer}`}
            width='100%'
            height='100%'
            url={this.props.url}
            playing={false}
            controls={true}
            loop={loop}
            volume={volume}
            muted={false}
          />
        </div>
      );
    } else {
      return (
        <div style={{ overflow: 'hidden', position: 'relative' }}>
          <div className={`${theme.playerWrapper} ${this.props.className} `} style={divStyle}>
            <ReactPlayer
              className={`${theme.reactPlayer} ${this.props.mobileClass}`}
              width='100.25%'
              height='100.5%'
              url={this.props.url}
              playing={true}
              playsinline={true}
              controls={controls}
              loop={loop}
              volume={volume}
              muted={muted}
              config={{
                vimeo: {
                  playerOptions: {
                    background: true,
                  },
                },
              }}
              style={{
                marginTop: -1,
              }}
            />
          </div>
        </div>
      );
    }
  }
}

export default Video;
